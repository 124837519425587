<template>
  <v-container v-if="vueVerCliente">
    <v-row>
      <v-col cols="12">
        <PageToolbar :title="cliente.nome" icon="assignment_ind" dark add>
          <template v-slot:add>
            <v-sheet
              v-if="cliente.classificacao"
              color="lightgreen"
              class="white--text px-4 py-2 font-weight-bold text-body-2"
              rounded
            >
              {{ cliente.classificacao }}
            </v-sheet>
          </template>
        </PageToolbar>
      </v-col>

      <v-col cols="12">
        <v-card :loading="loading" :disabled="loading">
          <v-container class="py-0">
            <v-row>
              <v-col
                cols="12"
                md="2"
                class="pt-8 lightgray"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                    : ''
                "
              >
                <div class="d-flex justify-center align-center">
                  <v-hover v-slot:default="{ hover }">
                    <v-avatar
                      size="120"
                      style="box-shadow: 0px 0px 0px 3px rgba(32,140,110,1);"
                    >
                      <v-img
                        :src="
                          cliente.avatar
                            ? apiUrl + cliente.avatar
                            : require('@/assets/avatar_placeholder.png')
                        "
                      >
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            class="d-flex transition-fast-in-fast-out darkblue v-card--reveal white--text"
                            style="height: 34px; "
                          >
                            <v-btn
                              depressed
                              text
                              x-small
                              dark
                              class="mt-1"
                              @click="dialogImages = true"
                            >
                              alterar
                            </v-btn>
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </v-avatar>
                  </v-hover>
                </div>
                <v-card outlined class="mt-8">
                  <v-tabs v-model="tab" vertical>
                    <v-tab class="justify-start">Dados Gerais</v-tab>
                    <v-tab class="justify-start"> Eventos</v-tab>
                    <v-tab class="justify-start"> Conexões</v-tab>
                    <v-tab class="justify-start"> Financeiro</v-tab>
                    <v-tab class="justify-start"> Notas</v-tab>
                  </v-tabs>
                </v-card>
              </v-col>
              <v-col cols="12" md="10" class="pa-0">
                <v-card-text class="pa-0">
                  <v-tabs-items v-model="tab">
                    <!-- Dados Gerais -->
                    <v-tab-item class="pa-4">
                      <v-row>
                        <!-- Nome -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="cliente.nome"
                            label="Nome"
                            dense
                            filled
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- Adm Id -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="cliente.adm_id"
                            label="Adm Id"
                            dense
                            filled
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- Login Radius -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="cliente.login_radius"
                            label="Login Radius"
                            dense
                            filled
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- Documento -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="cliente.documento"
                            label="Documento"
                            dense
                            filled
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- Status -->
                        <v-col cols="12" md="6">
                          <v-card
                            flat
                            style="background-color: rgba(0, 0, 0, 0.06); border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important; border-bottom-left-radius: 0px !important; border-bottom-right-radius: 0px !important;"
                            height="56px"
                            class="pa-3 d-flex justify-space-between align-center"
                          >
                            <v-subheader
                              style="height: auto !important"
                              class="ma-0 pa-0 body-1"
                              >Status</v-subheader
                            >

                            <v-switch
                              class="ma-0 pa-0"
                              hide-details
                              :false-value="0"
                              :true-value="1"
                              v-model="cliente.status"
                            ></v-switch>
                          </v-card>
                        </v-col>

                        <!-- Endereco -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="cliente.endereco"
                            label="Endereco"
                            dense
                            filled
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- celular -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="cliente.celular"
                            label="Celular"
                            dense
                            filled
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- telefone -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="cliente.telefone"
                            label="Telefone"
                            dense
                            filled
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- localizacao -->
                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="cliente.localizacao"
                            label="Localizacao"
                            dense
                            filled
                            hide-details
                          ></v-text-field>
                        </v-col>

                        <!-- Cidade -->
                        <v-col cols="12" md="6">
                          <v-select
                            v-model.number="cliente.cidade_id"
                            :items="cidades"
                            clearable
                            item-text="nome"
                            item-value="id"
                            dense
                            filled
                            hide-details
                            label="Cidades"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-tab-item>

                    <!-- Eventos -->
                    <v-tab-item>
                      <ListaEventosCliente :clienteId="clienteId" />
                    </v-tab-item>

                    <!-- Conexoes -->
                    <v-tab-item>
                      <ClienteConexoes
                        :cliente_id="clienteId"
                        :adm_id="cliente.adm_id"
                        :login_radius="cliente.login_radius"
                        :cliente_nome="cliente.nome"
                      />
                    </v-tab-item>

                    <!-- ClienteFinanceiro -->
                    <v-tab-item>
                      <ClienteFinanceiro
                        :cliente_id="clienteId"
                        :adm_id="cliente.adm_id"
                      />
                    </v-tab-item>

                    <!-- ClienteNotas -->
                    <v-tab-item>
                      <ClienteNotas :cliente_id="clienteId" />
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      @keydown.esc="voltarEventos"
      v-model="dialog"
      @click:outside="voltarEventos"
      width="100%"
      max-width="800px"
      scrollable
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <router-view></router-view>
    </v-dialog>

    <SelectImageModal
      :dialogImages.sync="dialogImages"
      origem="clientes"
      :origem_id="clienteId"
    >
      <template v-slot:saveSelected>
        <v-spacer></v-spacer>
        <v-fade-transition>
          <v-btn
            v-if="imagemSelecionada"
            depressed
            dark
            small
            color="toolbar"
            @click="updateAvatar(imagemSelecionada.path_original)"
          >
            Salvar
            <v-icon>save</v-icon>
          </v-btn>
        </v-fade-transition>
      </template>
    </SelectImageModal>

    <v-btn
      v-if="vueEditCliente"
      @click="updateCliente"
      :disabled="!valid"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>save</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { fetchCidades } from "@/services/api/cidades.api.js";
import {
  getCliente,
  putCliente,
  getImages,
  uploadImage,
} from "@/services/api/clientes.api.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Cliente",
  props: ["clienteId"],
  components: {
    ListaEventosCliente: () => import("./components/ListaEventosCliente.vue"),
    ClienteConexoes: () => import("./components/ClienteConexoes.vue"),
    ClienteFinanceiro: () => import("./components/ClienteFinanceiro.vue"),
    ClienteNotas: () => import("./components/ClienteNotas.vue"),
    SelectImageModal: () => import("@/components/SelectImageModal"),
  },
  data: () => ({
    cliente: {},
    cidades: [],
    loading: false,
    tab: null,
    selectedFile: null,
    apiUrl: null,
    images: [],
    avatar: "",
    dialogImages: false,
    carregandoImagem: false,
    dialog: false,
  }),
  computed: {
    valid() {
      let valid = false;
      if (
        this.cliente.documento &&
        this.cliente.nome &&
        this.cliente.adm_id != null &&
        this.cliente.cidade_id
      ) {
        valid = true;
      }
      return valid;
    },
    cliente_id() {
      return this.$route.params.clienteId;
    },
    ...mapState({
      imagemSelecionada: (state) => state.imagemSelecionada,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueVerCliente() {
      return this.getAccess("vueCliente", "visualizar");
    },
    vueEditCliente() {
      return this.getAccess("vueCliente", "editar");
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(newVal, oldVal) {
        this.dialog = newVal.meta && newVal.meta.dialog;
      },
    },
  },
  methods: {
    voltarEventos(event) {
      this.$router.push({
        name: "Cliente",
        params: { clienteId: this.cliente_id },
      });
    },
    getCliente() {
      this.$Progress.start();
      return getCliente(this.cliente_id)
        .then((response) => {
          this.cliente = response;
          this.$Progress.finish();
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.cliente.nome
          ) {
            this.$store.commit("UPDATE_DYNAMICCRUMB", this.cliente.nome);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    getCidades() {
      return fetchCidades()
        .then((response) => {
          this.cidades = response;
        })
        .catch((error) => console.log(error));
    },
    updateCliente() {
      const cliente = this.cliente;
      delete cliente.classificacao;

      this.loading = true;
      this.$Progress.start();
      putCliente(this.cliente_id, cliente)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Cliente atualizado com sucesso!");
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    uploadImage(fd) {
      if (fd) {
        this.$Progress.start();
        this.carregandoImagem = true;
        uploadImage(fd)
          .then((response) => {
            if (response.status === 200) {
              this.$toast.success("Imagem adicionada com sucesso!");
              this.$Progress.finish();
              this.getClienteImages();
              this.carregandoImagem = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$Progress.fail();
            this.carregandoImagem = false;
          });
      }
    },
    onFileSelected(event, id) {
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("image", this.selectedFile, this.selectedFile.name);
      fd.append("cliente_id", id);
      this.uploadImage(fd);
    },
    getClienteImages() {
      return getImages(this.clienteId)
        .then((response) => {
          this.images = response;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateAvatar(image) {
      this.cliente.avatar = image;
      this.updateCliente();
      this.dialogImages = false;
    },
  },

  async mounted() {
    if (this.vueVerCliente) {
      this.loading = true;
      await this.getClienteImages();
      if (process.env.VUE_APP_API_URL) {
        this.apiUrl = process.env.VUE_APP_API_URL;
      }
      await this.getCliente();
      await this.getCidades();

      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.btn-toggle-mobile {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  & > .v-btn.v-btn:not(:first-child) {
    border-left-width: thin;
  }
  & > .v-btn.v-btn:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  & > .v-btn.v-btn:last-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
